import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';

function GridBox({ headerText, contentText, sub_text, contentFontSize }) {
  return (
    <div className=" flex flex-col flex-auto shadow rounded-2xl overflow-hidden text-center">
      {/* 🔹 ホバー時に表示されるサブタイトル */}
      <Tooltip title={sub_text} arrow placement="top">
        <div className="bg-white h-full py-4 px-2">
            <p className="text-base font-semibold cursor-pointer">{headerText}</p>
          <Typography className=" text-blue-600 pt-5 text-2xl font-bold tracking-tight leading-non" fontSize={contentFontSize} fontWeight={700}>
            {contentText}
          </Typography>
        </div>
      </Tooltip>
    </div>
  );
}

export default GridBox;