import React, { useEffect, useState } from "react";

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MuiDialog from "@mui/material/Dialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Dialog(props) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const content = props.content ? props.content : "";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = () => {
    setMessage(content);
  };

  useEffect(() => {
    if(content) {
      setOpen(true);
      handleClick();
    }
    console.log(content);
  }, [content]);

  const handleDelete = () => {
    props.onDelete(); // 親から渡された削除コールバックを呼び出す
    setOpen(false);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
    // 親にダイアログが閉じたことを伝えるコールバックを呼び出す
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div>
      {content ? (
        <>
          <MuiDialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                width: '450px',
                height: '200px',
              },
            }}
          >
            <DialogContent>
              <DialogContentText
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',    // 上下の中央寄せ
                  justifyContent: 'center', // 左右の中央寄せ
                  height: '100%',
                  width: '100%',
                  fontWeight: 600       // セミボールドに設定
                }}
              >
                {message}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '24px',
                padding: '18px'
              }}
            >
              <button
                className="px-4 py-2 border-[1px] border-gray-300 rounded-md font-semibold"
                onClick={handleClose}
              >
                キャンセル
              </button>
              <button
                className={`px-9 py-2 text-white rounded-md font-semibold
                  ${
                    props.buttonText === '削除' ? 'bg-[#D71959]' : 'bg-[#2978CA]'
                  }`}
                onClick={handleDelete}
              >
                {props.buttonText}
              </button>
            </DialogActions>
          </MuiDialog>
        </>
      ) : null}
    </div>
  );
}