import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Notification from '../notification/notification';
import Error from '../notification/error';
import { TimeContext } from '../../context/TimeContext';

const TimeSetting = ({ title, buttonTextBlue, buttonTextRed, userId }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { startHour, setStartHour, startMinute, setStartMinute, endHour, setEndHour, endMinute, setEndMinute } = useContext(TimeContext);
  const [isCalled, setIsCalled] = useState('無効');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  // 時間のリスト 6時から22時
  const hours = Array.from({ length: 17 }, (_, i) => i + 6);
  // 分のリスト 0分から50分（10分ごと）
  const minutes = Array.from({ length: 6 }, (_, i) => i * 10);

  useEffect(() => {
    messageReset();
    if(userId==='1' || userId==='0') return;

    const token = localStorage.getItem('token');
    const params = {};

    params['user_id'] = userId;

    axios.get(`${apiUrl}/api/phone_number/register/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    })
    .then(response => {
      const phone_number = response.data.phone_number;
      setPhoneNumber(phone_number);
      
      params['phone_number'] = phone_number;

      axios.get(`${apiUrl}/api/calling_time/reflect/`, {
        headers: { Authorization: `Bearer ${token}` },
        params: params
      })
      .then(response => {
        setIsCalled(response.data.status);

        if(response.data.startTime!=='0000' && response.data.endTime!=='0000') {
          if(response.data.startTime && response.data.endTime) {
            const startTime = response.data.startTime;
            const endTime = response.data.endTime;
            reFormatTime(startTime, endTime);
          }
        }else {
          setStartHour('-');
          setStartMinute('-');
          setEndHour('-');
          setEndMinute('-');
        }
      })
      .catch((error) => {
        setError(
          error.response?.data?.error || '架電時間情報が取得できませんでした。'
        );
      });
    })
    .catch((error) => {
      setError(
        error.response?.data?.error || '電話番号が取得できませんでした。'
      );
    });
  }, [apiUrl, userId]);

  const messageReset = () => {
    setError('');
    setMessage('');
  }

  const formatTime = (hour, minute) => {
    const formattedHour = hour.padStart(2, '0');
    const formattedMinute = minute.padStart(2, '0');

    return [formattedHour, formattedMinute];
  }
  const reFormatTime = (startTime, endTime) => {
    // startTimeの時と分を取得
    const sHour = parseInt(startTime.slice(0, 2), 10);
    let sMinute = parseInt(startTime.slice(2), 10);
    sMinute = sMinute === 0 ? 0 : sMinute;  // 00分は0に変換
    setStartHour(sHour);
    setStartMinute(sMinute);
    
    // endTimeの時と分を取得
    const eHour = parseInt(endTime.slice(0, 2), 10);
    let eMinute = parseInt(endTime.slice(2), 10);
    eMinute = eMinute === 0 ? 0 : eMinute;  // 00分は0に変換
    setEndHour(eHour);
    setEndMinute(eMinute);
  }

  const timeSet = async() => {
    messageReset();

    const token = localStorage.getItem('token');
    const params = {};

    if(userId !== '1') {
      params['user_id'] = userId;
    }else {
      setError('利用者が選択されていません。');
      return;
    }

    if(phoneNumber) {
      params['phone_number'] = phoneNumber;
    }else {
      return;
    }
    if(startHour && startMinute) {
      params['start_hour'] = startHour;
      params['start_min'] = startMinute;
      // 現在時刻
      const now = new Date();
      const nowHour = now.getHours()*60;
      const nowMinute = now.getMinutes();

      // 比較用に整数型にする
      const choiceHour = parseInt(startHour, 10)*60;
      const choiceMinute = parseInt(startMinute, 10);

      if(!((nowHour+nowMinute<=choiceHour+choiceMinute) && (choiceHour+choiceMinute<=nowHour+nowMinute+10))) {
        const [hour, minute] = formatTime(startHour, startMinute);
        params['start_time'] = hour + minute;
      }else {
        setError('現在時刻から10分間は設定できません。')
        return;
      }
    }else {
      setError('架電開始時間を選択してください。');
      return;
    }
    if(endHour && endMinute) {
      params['end_hour'] = endHour;
      params['end_min'] = endMinute;
      // 比較用に整数型にする
      const choiceStartHour = parseInt(startHour, 10)*60;
      const choiceStartMinute = parseInt(startMinute, 10);
      const choiceEndHour = parseInt(endHour, 10)*60;
      const choiceEndMinute = parseInt(endMinute, 10);

      if(choiceStartHour+choiceStartMinute < choiceEndHour+choiceEndMinute) {
        const [hour, minute] = formatTime(endHour, endMinute);
        params['end_time'] = hour + minute;
      }else {
        setError('架電開始時間よりも後の時間を設定して下さい。');
        return;
      }
    }else {
      setError('架電終了時間を選択してください。');
      return;
    }

    axios.post(`${apiUrl}/api/calling_time/reflect/`, params, {
      headers: { 
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => {
      const { message, status } = response.data;
      setIsCalled(status);
      setMessage(message);
    })
    .catch ((error) => {
      console.log('Error: ', error);
      if(error.response && error.response.data && error.response.data.error) {
        setIsCalled('設定エラー');
        setError(error.response.data.error);
      }else {
        setIsCalled('設定エラー');
        setError('設定反映できませんでした。');
      }
    });
  };

  const callingStop = async() => {
    messageReset();

    const token = localStorage.getItem('token');
    const data = {};

    if(userId !== '1') {
      data['user_id'] = userId;
    }else {
      setError('利用者が選択されていません。');
      return;
    }
    if(phoneNumber) {
      data['phone_number'] = phoneNumber;
    }else {
      setError('この利用者は電話番号が設定されていません。');
      return;
    }

    // 現在時刻
    const now = new Date();
    const nowHour = now.getHours()*60;
    const nowMinute = now.getMinutes();
    // 比較用に整数型にする
    const choiceHour = parseInt(startHour, 10)*60;
    const choiceMinute = parseInt(startMinute, 10);
    if((nowHour+nowMinute<=choiceHour+choiceMinute) && (choiceHour+choiceMinute<=nowHour+nowMinute+10)) {
      setError('架電開始時間前の10分間は停止できません。');
      return;
    }

    axios.put(`${apiUrl}/api/calling_time/reflect/`, data, {
      headers: { 
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => {
      const { message, status } = response.data;

      setStartHour('-');
      setStartMinute('-');
      setEndHour('-');
      setEndMinute('-');
      setIsCalled(status);
      setMessage(message);
    })
    .catch ((error) => {
      console.log('Error: ', error);
      if(error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      }else {
        setError('架電停止できませんでした。');
      }
    })
  };

  return (
    <div className="flex flex-col justify-between py-6 bg-white rounded-lg custom-shadow">
      <h2 className="px-6 text-xl text-gray-900 font-bold pb-4 border-b-[1px] border-gray-300">
        {title}
      </h2>
      <div className='h-full p-2'>
        <ol className='pl-6 list-decimal font-semibold'>
          <li>架電開始/終了時間を選択し、「設定反映」ボタンをクリックしてください。</li>
        </ol>
        <div className='flex items-center ml-1 font-semibold text-[#E83929]'>
          ※ 架電開始時間前の10分間は設定できません。また、架電開始時間10分前よりも前に設定した際には次の日に架電が行われます。
        </div>
      </div>
      <div className='h-full flex justify-center items-end'>
        <div>
          <div className='pl-2 pb-1 font-bold'>架電ステータス</div>
          {isCalled==='有効' ? 
              <label className="w-48 h-20 flex justify-center items-center text-2xl font-bold text-white bg-blue-500">{isCalled}</label>
            : <label className="w-48 h-20 flex justify-center items-center text-2xl font-bold text-white bg-[#D71959]">{isCalled}</label>
          }
        </div>
        <div>
          <div className="flex flex-col gap-3 items-center pl-9">
            <div className="flex items-center gap-9">
              <label className="mr-2 font-bold text-gray-600">架電開始時間</label>
              <div>
                <select
                  className={`w-16 mr-1 border rounded-md px-2 py-1
                    ${isCalled==='有効' ?  'cursor-not-allowed': null}`}
                  value={startHour}
                  onChange={(e) => setStartHour(e.target.value)}
                >
                  {/* 初期値 */}
                  <option value="">-</option>
                  {/* 時間範囲 */}
                  {hours.map(hour => (
                    <option key={hour} value={hour}>{`${hour}`}</option>
                  ))}
                </select>時
              </div>
              <div>
                <select
                  className={`w-16 mr-1 border rounded-md px-2 py-1 ml-2
                    ${isCalled==='有効' ?  'cursor-not-allowed': null}`}
                  value={startMinute}
                  onChange={(e) => setStartMinute(e.target.value)}
                >
                  {/* 初期値 */}
                  <option value="">-</option>
                  {/* 時間範囲 */}
                  {minutes.map(min => (
                    <option key={min} value={min}>{`${min}`}</option>
                  ))}
                </select>分
              </div>
            </div>
            <div className="flex items-center gap-9">
              <label className="mr-2 font-bold text-gray-600">架電終了時間</label>
              <div>
                <div></div>
                <div></div>
                <select className={`w-16 mr-1 border rounded-md px-2 py-1
                  ${isCalled==='有効' ?  'cursor-not-allowed': null}`}
                  value={endHour}
                  onChange={(e) => setEndHour(e.target.value)}
                >
                  {/* 初期値 */}
                  <option value="">-</option>
                  {/* 時間範囲 */}
                  {hours.map(hour => (
                    <option key={hour} value={hour}>{`${hour}`}</option>
                  ))}
                </select>時
              </div>
              <div>
                <select className={`w-16 mr-1 border rounded-md px-2 py-1 ml-2
                  ${isCalled==='有効' ?  'cursor-not-allowed': null}`}
                  value={endMinute}
                  onChange={(e) => setEndMinute(e.target.value)}
                >
                  {/* 初期値 */}
                  <option value="">-</option>
                  {/* 時間範囲 */}
                  {minutes.map(min => (
                    <option key={min} value={min}>{`${min}`}</option>
                  ))}
                </select>分
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='h-full'></div>
      <div className="h-full px-2 flex justify-end items-end gap-5">
        <button
          className={`px-4 py-2 text-white rounded-md font-semibold ${
            userId==='1'||isCalled!=='有効' ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#D71959]'
          }`}
          disabled={userId === '1'}
          onClick={callingStop}
        >{buttonTextRed}</button>
        <button
          className={`px-4 py-2 text-white rounded-md font-semibold ${
            userId==='1'||isCalled==='有効' ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#2978CA]'
          }`}
          disabled={userId === '1'}
          onClick={timeSet}
        >{buttonTextBlue}</button>
      </div>
      {message && <Notification content={message} />}
      {error && <Error content={error} />}
    </div>
  );
};

export default TimeSetting;
