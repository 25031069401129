import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Error from "../notification/error";
import Notification from "../notification/notification";
import Dialog from '../notification/dialog';
import { TimeContext } from '../../context/TimeContext';

const SmsCard = ({ buttonTextBlue, buttonTextRed, userId }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  // 各項目のステートとエラーメッセージ用のステートを追加
  const [sms, setSms] = useState('');
  const [smsSendFlag, setSmsSendFlag] = useState(false);
  const [smsSendMessage, setSmsSendMessage] = useState('');
  const [isSmsSned, setIsSmsSend] = useState(false);
  const { startHour, startMinute, endHour, endMinute } = useContext(TimeContext);
  const [errors, setErrors] = useState();

  const sms_placeholder = `UR転職です。
先ほどはお電話ありがとうございました。
以下URLから面談希望日時を入力下さい。
              
https://XXX
              
※電話でのお問い合わせはこちら：0120-XX-XXXX
※本SMSは送信専用です。`

  useEffect(() => {
    resetMessage();

    if(userId==='1' || userId==='0') return;

    const token = localStorage.getItem('token');
    const params = {};

    params['user_id'] = userId;

    axios.get(`${apiUrl}/api/short_message_service/sms/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    })
    .then(response => {
      setSms(response.data.smsBody);
      setSmsSendFlag(response.data.smsSendFlag);
    })
    .catch((error) => {
      setSms('');
      console.log('error: ', error);
      setError(
        error.response?.data?.error
      );
    });
  }, [apiUrl, userId]);

  const resetMessage = () => {
    setError('');
    setMessage('');
    setErrors('');
    setIsSmsSend(false);
    setSmsSendFlag(false);
    setSmsSendMessage('');
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSms(value);
    setErrors(''); // エラーがあればリセット
  };
  const handleChange = () => {
    setSmsSendFlag(true);
    setSmsSendMessage('');
    setIsSmsSend(false);
  }
  const handleDialogOpen = () => {
    setSmsSendMessage(`SMS送信機能は、別途費用が発生する有償オプションです。
      送信が実行されると料金が発生します。`);
    setIsSmsSend(true);
  }
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      handleDialogOpen();  // チェックが入るときのみダイアログを表示
    }else {
      setSmsSendFlag(e.target.checked); // チェックを外すときは即座に変更
    }
  };

  const validateFields = () => {
    if (smsSendFlag && sms.length===0) {
      setErrors('SMS本文を入力してください。');
      return false;
    }else if (sms.length > 140) {
      setErrors('SMS本文は140文字以内で入力してください。');
      return false;
    }else {
      setErrors('');
      return true;
    }
  };
  const timeCheck = () => {
    // 現在時刻
    const now = new Date();
    const nowHour = now.getHours()*60;
    const nowMinute = now.getMinutes();
    // 比較用に整数型にする
    const setStartHour = parseInt(startHour, 10)*60;
    const setStartMinute = parseInt(startMinute, 10);
    const setEndHour = parseInt(endHour, 10)*60;
    const setEndMinute = parseInt(endMinute, 10);
    if((nowHour+nowMinute >= setStartHour+setStartMinute-10) && (nowHour+nowMinute <= setEndHour+setEndMinute)) {
      setError('架電開始時間前の10分前から架電時間中は登録・編集を行えません。');
      return false;
    }else {
      return true;
    }
  }

  const handleSubmit = async (e) => {
    resetMessage();

    // バリデーションチェック
    e.preventDefault();
    if (timeCheck() && validateFields()) {
      const token = localStorage.getItem('token');
      const data = {};

      if(userId !== '1') {
        data['user_id'] = userId;
        data['sms_body'] = sms;
        data['sms_send_flag'] = smsSendFlag;
      }else {
        setError('利用者が選択されていません。');
        return;
      }

      axios.post(`${apiUrl}/api/short_message_service/sms/`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setMessage(response.data.status);
        setSms(sms);
        setSmsSendFlag(smsSendFlag);
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        }else {
          console.log(error);
          setError('登録できませんでした。');
        }
      });
    }
  };

  return (
    <div className=" bg-white rounded-lg custom-shadow py-6">
      <h2 className='pb-4 font-semibold text-2xl text-center'>SMS設定</h2>
      <div className='p-16 border-gray-300'>
        <form onSubmit={handleSubmit}>
          <div className='flex flex-col'>
            <div className='flex items-center'>
              <label className='pl-2 font-semibold'>SMS本文</label>
              <div className={`pl-4 text-sm ${sms.length > 140 ? 'text-red-500' : 'text-gray-500'}`}>
                ({sms.length}/140)
              </div>
            </div>
            <textarea
              name='sms'
              placeholder={sms_placeholder}
              className='border-[1px] px-2 py-1 rounded-md h-56'
              value={sms}
              onChange={handleInputChange}
            />
            {errors && <div className='text-[#E83929] font-semibold'>{errors}</div>}
          </div>
          <div className="flex justify-end items-center gap-5 pl-6 pt-5">
            <div className='flex items-center'>
              <div className='font-bold text-blue-700'>SMS送信</div>
              <input
                type="checkbox"
                name="sms_send_flag"
                checked={smsSendFlag}
                onChange={handleCheckboxChange}
                className="h-4 w-4 ml-2 text-blue-600 border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded"
              />
            </div>
            <button
              className={`px-4 py-2 text-white rounded-md font-semibold ${
                userId === '1' ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#2978CA]'
              }`}
              type='submit'
              disabled={userId === '1'}
            >
              {buttonTextBlue}
            </button>
          </div>
        </form>
      </div>
      {smsSendMessage && (
        <Dialog
          content={smsSendMessage}
          buttonText={'確認しました'}
          isSmsSned={isSmsSned}
          onDelete={() => handleChange()}
          onClose={() => resetMessage()}
        />
      )}
      {message && <Notification content={message} />}
      {error && <Error content={error} />}
    </div>
  );
};

export default SmsCard;
