import React, { useContext, useEffect, useState } from 'react';
import CallingCard from '../../component/dashboard/CallingCard';
import ReceivingCard from '../../component/dashboard/ReceivingCard';
import TimeSetting from '../../component/dashboard/TimeSetting';
import Container from '../../component/dashboard/Container';
import SelectUser from '../../component/dashboard/SelectUser';
import { UserContext } from '../../context/UserContext';
import SmsCard from '../../component/dashboard/SmsCard';

const CallSettingPage = () => {
  const { user } = useContext(UserContext);
  const [userId, setUserId] = useState('1');  // 利用者

  // ページ初回表示時のみ実行
  useEffect(() => {
    if(user.is_admin) {
      setUserId('1');
    }else {
      setUserId(user.user_id);
    }
  }, [user.is_admin]);

  // ユーザーIDの設定
  const handleUserIdChange = (userid) => {
    setUserId(userid);
  };

  return (
    <Container className="bg-[#f8f8fb] h-full flex-1">
      {user?.is_admin ? (
          <SelectUser onChange={handleUserIdChange} user='1' page='CallSetting' />
        ):null}
      <div className="bg-white rounded-lg custom-shadow p-6 pt-0 my-6">
        <h2 className='py-4 font-semibold text-2xl text-center'>架電設定</h2>
        <div className="grid grid-cols-2 gap-24">
          <CallingCard
            title="架電リスト"
            buttonTextBlue="登録"
            buttonTextRed="削除"
            userId={userId}
          />
          <TimeSetting
            title="時間設定"
            description="ファイルはサンプルを活用してください。"
            buttonTextBlue="設定反映"
            buttonTextRed="架電停止"
            userId={userId}
          />
        </div>
      </div>
      <div className='mb-6'>
        <ReceivingCard
          buttonTextBlue="登録"
          buttonTextRed="削除"
          userId={userId}
        />
      </div>
      <div>
        <SmsCard
          buttonTextBlue="登録"
          buttonTextRed="削除"
          userId={userId}
        />
      </div>
    </Container>
  );
};

export default CallSettingPage;
